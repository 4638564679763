<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/pipelineIotBind' }">管网设备绑定</el-breadcrumb-item>
          <el-breadcrumb-item>绑定设备</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="form-box">
        <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px">
          <div style="display:flex;justify-content:space-between;align-items:center;">
            <div class="form-title">管网信息</div>
          </div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="管网区划:">定边</el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="管线位置:">东环路</el-form-item>
            </el-col>
          </el-row>

          <!-- 设备信息 -->
          <div class="white-bg-card" style="padding: 0;">
            <configuration :equipmentss="equipments" :resource="'add'"></configuration>
          </div>

          <div class="bottom-btn">
            <el-button type="primary" size="small" @click="$router.push('/pipelineIotBind')">取消</el-button>
            <el-button type="primary" @click="onSubmit" size="small">提交</el-button>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>
  
<script>
import configuration from './configuration'
export default {
  components: {
    configuration,
  },
  data() {
    return {
        loading: false,
        row: {},
        form: {},
        rules: {
            eid:[{ required: true, message: '必填项', trigger: 'blur' }],
        },
        // 子设备绑定数据
        supplierCode: '',
        equipmentBrandCode: '',
        supplierData: [],//供应商数据
        brandData: [],//品牌数据
        brandLoading: false,//品牌Loading
        tableSubDeviceLoading: false,//列表Loading
        tableDataCp: [],
        tableSelectList: [],
        deviceTableData: [],
        eid: '',//网关设备eid

        equipments: [{
          drawer: false,
          configRadio: '',
          tagDrawer: false,
          tagRadio: '',
          gatewayEid:null,
          eid:null,
          equipmentBrandCode: null,
          equipmentName: null,
          dialogTypeVisible: false,
          tagCustom: [{
              tagDrawer: false,
              tagRadio: '',
              name: '',
              describe: null,
          }],
          longitude: '',
          latitude: '',
          address: ''
        }],
    };
  },
  methods: {
    onSubmit() {
      let form = {
        pipelineCategoryId: "PC301391015261310976",
        pipelineId: "P301736045968707584",
        bindEquipments:[]
      }
      let equipmentName = this.equipments.some(v=>!v.equipmentName)
      if(equipmentName) return this.$message.error('请选择设备')

      for(let i=0;i<this.equipments.length;i++) {
        let data = this.equipments[i]
        // Tag循环
        for(var j=0;j<data.tagCustom.length;j++) {
          if(!data.tagCustom[j].name) return this.$message.error('请选择Tag信息')
          if(!data.tagCustom[j].describe) return this.$message.error('请选择Tag描述')
        }
        if(!data.address) return this.$message.error('请选择位置')
      }

      this.equipments.forEach(v=>{
        let bindTags = []
        v.tagCustom.forEach(t=>{
          bindTags.push({
            tag: t.name,
            describe: t.describe
          })
        })
        form.bindEquipments.push({
          eid: v.eid,
          bindTags: bindTags
        })
      })
      console.log(form)
      this.loading = true
      this.$ajax.post('pipelineIotBindCreate',form).then(res => {
        this.loading = false
        this.$message.success('绑定成功')
        this.$router.push("/pipelineIotBind");
      }).catch(err => {
        this.loading = false
        this.$message.error('绑定失败')
      })
    },

    // 获取供应商
    loadSupplier() {
      this.$ajax.post('iotSupplierQuery').then(res => {
        if(res.data.length>0) {
          setTimeout(()=>{ //高亮选中
            this.$refs.supplierTree.setCurrentKey(res.data[0].supplierCode)
          },200)
          this.supplierData = res.data
          this.loadBrand(res.data[0].supplierCode)

          this.tableDataCp.forEach((v)=>{ // 已选择的设备项高亮选中
            if (this.tableSelectList.find(item => item.eid == v.eid)) {
              v.checked = true
            }else {
              v.checked = false
            }
          })
        }
      })
    },

    // 获取供应商下品牌
    loadBrand(supplierCode) {
      this.$ajax.post('supplierDeviceQuery',{
        supplierCode: supplierCode
      }).then(res => {
        if(res.data.length>0) {
          this.brandLoading = true
          setTimeout(()=>{ //高亮选中
            this.$refs.brandTree.setCurrentKey(res.data[0].equipmentBrandCode)
            this.brandLoading = false
          },200)
          this.brandData = res.data
          this.loadSupplierByEquipment(res.data[0].supplierCode, res.data[0].equipmentBrandCode)

          this.tableDataCp.forEach((v)=>{ // 已选择的设备项高亮选中
            if (this.tableSelectList.find(item => item.eid == v.eid)) {
              v.checked = true
            }else {
              v.checked = false
            }
          })
        }
      })
    },

    // 获取根据供应商、品牌筛选出的设备
    loadSupplierByEquipment(supplierCode, equipmentBrandCode) {
      this.tableSubDeviceLoading = true
      this.$ajax.post('supplierByEquipment',{
        supplierCode: supplierCode,
        equipmentBrandCode: equipmentBrandCode,
        pageNum: 0,
        pageSize: 0
      }).then(res => {
        res.data.forEach(item => {
          item.checked = false
          item.deviceAlias = item.eid
        })
        this.tableDataCp = res.data
        this.tableSubDeviceLoading = false
        
        this.tableDataCp.forEach((v)=>{ // 已选择的设备项高亮选中
          if (this.tableSelectList.find(item => item.eid == v.eid)) {
            v.checked = true
          }else {
            v.checked = false
          }
        })
      }).catch(err=>{
        this.tableSubDeviceLoading = false
      })
    }
  },
  mounted() {
    // if (this.$route.params.row == null) {
    //     this.$router.push('/pipelineIotBind')
    // } else {
    //   this.row = this.$route.params.row
    // }
    // this.eid = this.row.eid
  }
};
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
.form-box {
  max-width: 1200px;
}
.table-tree {
  display: flex;
  height: ~'calc(100% - 56px)';
  margin-top: 20px;
  .tree {
    width: 300px;
    border-right: 4px solid #f6f7fb;
    margin-right: 20px;
  }
  .table {
    flex: 1;
  }
  .operate {
    &:hover {
      color: #409EFF;
    }
  }
  .custom-tree-node {
    font-size: 14px;
  }
}

/deep/.el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  background-color: #409eff;
  color: white;
}
/deep/.el-form-item {
  margin-bottom: 12px;
}
</style>