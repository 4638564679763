<template>
    <div style="min-width: 850px;">
        <!-- 设备信息 -->
        <div style="margin-bottom:20px;display:flex;align-items:center;justify-content:space-between;">
            <div class="form-title" style="margin-bottom:0;">设备信息</div>
            <el-button type="primary" size="small" @click="addDevice">新增设备</el-button>
        </div>
        <!-- 循环设备 -->
        <div class="custom-attr" v-for="(dItem,dIndex) in equipments" :key="dIndex">
            <div style="display: flex;justify-content: space-between;border-bottom: 1px solid rgb(241 241 241);padding-bottom: 15px;margin-bottom: 15px;" :style="dItem.equipmentName?'border-bottom: 1px solid rgb(241 241 241);padding-bottom: 15px;margin-bottom: 15px;':''">
                <div style="display: flex;justify-content: space-between;">
                    <el-form-item label="设备名称" label-width="70px">
                        <el-input v-model="dItem.equipmentName" size="small" style="width: 70%;" disabled placeholder="请选择设备"></el-input>
                        <el-button type="primary" size="small" @click="selectDevice(dItem)">选择设备</el-button>
                    </el-form-item>
                </div>
                <div>
                    <div v-if="dIndex!=0" class="close-icon" @click="delDevice(dIndex)">
                        <i class="el-icon-close"></i>
                    </div>
                </div>
            </div>
            <!-- <div v-show="dItem.equipmentName"> -->
                <div style="border-bottom: 1px solid rgb(241 241 241);padding-bottom: 15px;margin-bottom: 15px;">
                    <el-row :gutter="20">
                        <el-col style="width:79%;">
                            <el-form-item label="点表信息" label-width="70px"></el-form-item>
                        </el-col>
                        <el-col style="width:21%;display:flex;justify-content:flex-end;">
                            <el-button v-show="dItem.tagCustom.length>1" type="danger" size="mini" @click="delTag(dItem.tagCustom)">删除Tag</el-button>
                            <el-button type="primary" size="mini" @click="addTag(dItem.tagCustom)">新增点表</el-button>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20">
                        <div v-for="(tagItem, tagIndex) in dItem.tagCustom" :key="tagIndex">
                            <el-col :span="4">
                                <el-form-item label="Tag" label-width="50px">
                                    <el-button v-if="!tagItem.name" class="lube-btn" type="primary" size="small" @click.prevent="selectTagItem(tagItem,dItem)">选择Tag</el-button>
                                    <span v-else class="close-lube-btn">
                                        {{tagItem.name}}
                                        <span class="el-icon-error close-lube" @click="clearTagItem(tagItem)"></span>
                                    </span>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="描述" label-width="50px">
                                    <el-input v-model="tagItem.describe" size="small" placeholder="请输入描述"></el-input>
                                </el-form-item>
                            </el-col>
                            <!-- 侧滑选择Tag -->
                            <el-drawer
                                title="选择Tag"
                                :with-header="false"
                                :visible.sync="tagItem.tagDrawer"
                                direction="rtl"
                                size="800px">
                                <div class="drawer-title">选择Tag</div>
                                <div class="drawer-content table-tree">
                                    <el-table :data="tagTableData" height="700" v-loading="abilityLoading" element-loading-spinner="el-icon-loading">
                                        <el-table-column label="勾选" width="70">
                                            <template slot-scope="scope">
                                                <el-radio-group v-model="tagItem.tagRadio" @change="checkTagChange(scope.row,dItem)">
                                                    <el-radio :label="scope.row.id">{{''}}</el-radio>
                                                </el-radio-group>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="number" label="序号" width="50"></el-table-column>
                                        <el-table-column prop="dataType" label="数据类型"></el-table-column>
                                        <el-table-column prop="pointName" label="点名"></el-table-column>
                                        <el-table-column prop="describe" label="Tag描述" width="240"></el-table-column>
                                        <el-table-column prop="readWriteType" label="读写方向"></el-table-column>
                                        <el-table-column prop="cycle" label="采集周期"></el-table-column>
                                    </el-table>
                                </div>
                                <div class="drawer-bottom">
                                    <el-button size="small" type="primary" @click="tagItem.tagDrawer=false">取消</el-button>
                                    <el-button
                                        size="small"
                                        type="primary"
                                        @click="tagItemDrawerOK(tagItem, dItem)"
                                        class="drawer-bottom-ok">确 定</el-button>
                                </div>
                            </el-drawer>
                        </div>
                    </el-row>
                </div>
                <el-row :gutter="20">
                    <el-col style="width:79%;">
                        <el-form-item label="位置信息" label-width="70px"></el-form-item>
                    </el-col>
                    <el-col style="width:21%;display:flex;justify-content:flex-end;margin-top: 5px;">
                        <el-button type="primary" size="mini" @click="selectLocation(dItem,dIndex)">选择位置</el-button>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="详细位置" label-width="100px">
                            {{ dItem.address?dItem.address:'无' }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-row :gutter="20">
                            <el-col :span="12">
                                <el-form-item label="经度" label-width="100px">
                                    {{ dItem.longitude?dItem.longitude:'无' }}
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="纬度" label-width="100px">
                                    {{ dItem.latitude?dItem.latitude:'无' }}
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
                <el-dialog class="pipeline-dialog" title="管网位置" width="60%" top="15vh" :close-on-click-modal="false" :visible.sync="dItem.dialogTypeVisible" :show-close="false">
                    <div slot="footer" class="dialog-footer">
                        <div :id="'container-pipeline-map'+dIndex" style="width: 100%;height: 525px;margin-bottom: 20px;"></div>
                        <el-button size="small" @click="dialogTypeCancel(dItem)">取 消</el-button>
                        <el-button size="small" type="primary" @click="dialogTypeConfirm(dItem)">确 定</el-button>
                    </div>
                </el-dialog>
            <!-- </div> -->
            <!-- 选择设备类型 -->
            <el-drawer
                title=""
                :with-header="false"
                :visible.sync="dItem.drawer"
                direction="rtl"
                size="1000px">
                <div class="drawer-title">选择设备类型</div>
                <div class="drawer-content">
                    <div class="table-tree">
                        <div class="tree">
                            <div class="tree-title">
                                <div>
                                    <i class="el-icon-share" style="margin-right:10px;"></i>设备类型
                                </div>
                            </div>
                            <div class="tree-park" v-for="(item,index) in supplierData" :key="index">
                                <div class="park-title" :class="current==item.equipmentBrandCode?'bright':''" @click.prevent="brandToggle(item.equipmentBrandCode)">
                                <span class="class-icon iconfont icon-xiangmu" :style="current==item.equipmentBrandCode?'color:#FFF;':'color:#3498db;'"></span>
                                <span>{{item.equipmentBrand}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="table">
                            <el-table :data="tableData" style="width: 99%" height="700" v-loading="tableLoading" element-loading-spinner="el-icon-loading">
                                <el-table-column label="勾选" width="70">
                                    <template slot-scope="scope">
                                        <el-radio-group v-model="dItem.configRadio" @change="checkConfig(scope.row,dItem)">
                                            <el-radio :label="scope.row.eid">{{''}}</el-radio>
                                        </el-radio-group>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="waterworks" label="水厂">
                                    <template slot-scope="scope">
                                    {{ scope.row.waterworks?scope.row.waterworks:'-' }}
                                    </template>
                                </el-table-column>
                                <el-table-column prop="position" label="泵房编号">
                                    <template slot-scope="scope">
                                    {{ scope.row.position?scope.row.position:'-' }}
                                    </template>
                                </el-table-column>
                                <el-table-column prop="equipmentName" label="设备名称">
                                    <template slot-scope="scope">
                                    {{scope.row.equipmentName?scope.row.equipmentName:'-'}}
                                    </template>
                                </el-table-column>
                                <el-table-column prop="eid" label="设备号"></el-table-column>
                            </el-table>
                        </div>
                    </div>
                </div>
                <div class="drawer-bottom">
                    <el-button size="small" type="primary" @click="dItem.drawer=false">取消</el-button>
                    <el-button size="small" type="primary" @click="drawerOK(dItem)" class="drawer-bottom-ok">确 定</el-button>
                </div>
            </el-drawer>
        </div>
        
    </div>
</template>
<script>
import gaode from '@/js/mapApi/gaode.js'
import tianditu from '@/js/mapApi/tianditu.js'
import gcoord from 'gcoord';
export default {
    data() {
        return {
            supplierData: [],
            tableData: [],
            treeLoading: false,
            tableLoading: false,
            abilityLoading: false,
            current: null,
            selectCache: {},
            selectConfigObj: {},
            equipments: this.equipmentss,
            tagTableData: [],
            map: null,
            dialogTypeVisible: false,
            location: {}
        }
    },
    props: {
        equipmentss: {
            type: Array
        },
        resource: {
            type: String
        }
    },
    methods: {
        // 选择位置
        selectLocation(dItem,dIndex) {
            dItem.dialogTypeVisible = true;
            if(this.$CURRENT_MAP=='GD') {
                if(this.map) this.map.destroy()
            }
            this.$nextTick(()=>{
                this.initMap(dItem,dIndex);
            })
        },
        // 分类dialog取消
        dialogTypeCancel(dItem) {
            dItem.dialogTypeVisible = false;
        },
        // 分类dialog确认
        dialogTypeConfirm(dItem) {
            dItem.address = this.location.address
            dItem.longitude = this.location.longitude
            dItem.latitude = this.location.latitude
            dItem.dialogTypeVisible = false;
        },
        // 经纬度转地址
        regeoCode(lnglat,dItem) {
            if(this.$CURRENT_MAP=='GD') {
                this.map.getAllOverlays('marker').forEach(v=>{
                    this.map.remove(v);
                })
                var marker = new AMap.Marker();
                this.map.add(marker);
                marker.setPosition(lnglat);
                var geocoder = new AMap.Geocoder({
                    radius: 1000 //范围，默认：500
                });
                geocoder.getAddress(lnglat, (status, result) => {
                    if (status === 'complete'&&result.regeocode) {
                        let address = result.regeocode.formattedAddress;
                        this.location.address = address
                        this.location.longitude = lnglat.lng
                        this.location.latitude = lnglat.lat
                    }else{
                        console.error('根据经纬度查询地址失败')
                    }
                });
            }
            if(this.$CURRENT_MAP=='TDT') {
                this.map.getOverlays().forEach(v=>{ 
                    if(v.getType()==2) {
                        this.map.removeOverLay(v)
                    }
                })
                let icon = new T.Icon({
                    iconUrl: require("../../img/online1.png"),
                    iconSize: new T.Point(30, 30),
                    iconAnchor: new T.Point(14, 28)
                });
                let marker = new T.Marker(new T.LngLat(lnglat.lng, lnglat.lat), {icon: icon});// 创建标注
                this.map.addOverLay(marker);
                marker.setLngLat(new T.LngLat(lnglat.lng, lnglat.lat));
                var geocode = new T.Geocoder();
                geocode.getLocation(lnglat, (result)=>{
                    if(result.getStatus() == 0) {
                        let address = result.formatted_address;
                        this.location.address = address
                        this.location.longitude = result.location.lon
                        this.location.latitude = result.location.lat
                    }else{
                        console.error('根据经纬度查询地址失败')
                    }
                })
            }
        },
        // 初始化地图
        initMap(dItem,dIndex) {
            if(this.$CURRENT_MAP=='GD') {
                this.map = gaode.GDMap("container-pipeline-map"+dIndex)
            }
            if(this.$CURRENT_MAP=='TDT') {
                this.map = tianditu.TDTMap("container-pipeline-map"+dIndex)
            }
            this.map.on('click',(e)=>{
                this.regeoCode(e.lnglat,dItem);
            })
            let item = {
                "id": 1,
                "companyId": 4,
                "pipelineCategoryId": "PC301391015261310976",
                "name": "定边123123",
                "color": "#0984e3",
                "createTime": 1712823617603,
                data: [{
                    "id": 1,
                    "companyId": 4,
                    "pipelineCategoryId": "PC301391015261310976",
                    "pipelineId": "P301736045968707584",
                    "location": "定边",
                    "length": "100",
                    "direction": "南北",
                    "diameter": "10",
                    "material": "水泥",
                    "thickness": "500",
                    "depth": "40",
                    "burialCondition": null,
                    "yearsInService": null,
                    "createTime": 1712905879334,
                    "coordinates": [
                        {
                            "longitude": "107.604532",
                            "latitude": "37.566324"
                        },
                        {
                            "longitude": "107.608245",
                            "latitude": "37.567316"
                        },
                        {
                            "longitude": "107.61108",
                            "latitude": "37.568072"
                        },
                        {
                            "longitude": "107.613971",
                            "latitude": "37.568843"
                        },
                        {
                            "longitude": "107.616041",
                            "latitude": "37.569389"
                        },
                        {
                            "longitude": "107.6162",
                            "latitude": "37.56945"
                        },
                        {
                            "longitude": "107.616339",
                            "latitude": "37.569524"
                        },
                        {
                            "longitude": "107.61645",
                            "latitude": "37.569601"
                        },
                        {
                            "longitude": "107.616548",
                            "latitude": "37.569705"
                        },
                        {
                            "longitude": "107.616626",
                            "latitude": "37.569829"
                        },
                        {
                            "longitude": "107.616675",
                            "latitude": "37.569927"
                        },
                        {
                            "longitude": "107.616704",
                            "latitude": "37.570019"
                        },
                        {
                            "longitude": "107.616721",
                            "latitude": "37.570216"
                        },
                        {
                            "longitude": "107.616824",
                            "latitude": "37.572702"
                        },
                        {
                            "longitude": "107.617009",
                            "latitude": "37.577332"
                        },
                        {
                            "longitude": "107.617183",
                            "latitude": "37.581316"
                        },
                        {
                            "longitude": "107.617203",
                            "latitude": "37.581763"
                        },
                        {
                            "longitude": "107.617249",
                            "latitude": "37.582908"
                        },
                        {
                            "longitude": "107.617273",
                            "latitude": "37.583991"
                        },
                        {
                            "longitude": "107.617323",
                            "latitude": "37.585241"
                        },
                        {
                            "longitude": "107.617418",
                            "latitude": "37.587044"
                        },
                        {
                            "longitude": "107.61753",
                            "latitude": "37.589414"
                        },
                        {
                            "longitude": "107.617557",
                            "latitude": "37.59001"
                        },
                        {
                            "longitude": "107.617588",
                            "latitude": "37.590383"
                        },
                        {
                            "longitude": "107.617672",
                            "latitude": "37.590897"
                        },
                        {
                            "longitude": "107.617741",
                            "latitude": "37.591203"
                        },
                        {
                            "longitude": "107.617898",
                            "latitude": "37.591613"
                        },
                        {
                            "longitude": "107.617988",
                            "latitude": "37.591833"
                        },
                        {
                            "longitude": "107.618137",
                            "latitude": "37.592131"
                        },
                        {
                            "longitude": "107.618314",
                            "latitude": "37.592456"
                        },
                        {
                            "longitude": "107.618399",
                            "latitude": "37.592618"
                        },
                        {
                            "longitude": "107.618548",
                            "latitude": "37.592966"
                        },
                        {
                            "longitude": "107.618628",
                            "latitude": "37.593176"
                        },
                        {
                            "longitude": "107.61868",
                            "latitude": "37.593389"
                        }
                    ]
                },{
                    "id": 11,
                    "companyId": 4,
                    "pipelineCategoryId": "PC305725759969103872",
                    "pipelineId": "P370240031088267264",
                    "location": "定红路",
                    "length": "1000",
                    "direction": "东西",
                    "diameter": "50",
                    "material": "水泥",
                    "thickness": "20",
                    "depth": "5",
                    "burialCondition": null,
                    "yearsInService": null,
                    "createTime": 1729238502138,
                    "coordinates": [
                        {
                            "longitude": "107.53425491225184",
                            "latitude": "37.58066267880888"
                        },
                        {
                            "longitude": "107.53800310752513",
                            "latitude": "37.58069289214669"
                        },
                        {
                            "longitude": "107.54067495779371",
                            "latitude": "37.580726138870546"
                        },
                        {
                            "longitude": "107.54597949811657",
                            "latitude": "37.5807833329076"
                        },
                        {
                            "longitude": "107.55061684556529",
                            "latitude": "37.580832855283866"
                        },
                        {
                            "longitude": "107.5509360069326",
                            "latitude": "37.58084216103806"
                        },
                        {
                            "longitude": "107.55100582292542",
                            "latitude": "37.58083200833161"
                        },
                        {
                            "longitude": "107.55142473041833",
                            "latitude": "37.58085110414009"
                        },
                        {
                            "longitude": "107.55498573197086",
                            "latitude": "37.580893653021285"
                        },
                        {
                            "longitude": "107.56317718891236",
                            "latitude": "37.58097829315934"
                        },
                        {
                            "longitude": "107.5694152360996",
                            "latitude": "37.58106838268708"
                        },
                        {
                            "longitude": "107.57913061517223",
                            "latitude": "37.581176228546504"
                        },
                        {
                            "longitude": "107.58213711537779",
                            "latitude": "37.5812233085545"
                        },
                        {
                            "longitude": "107.58235688034246",
                            "latitude": "37.58124311268055"
                        }
                    ]
                }]
            }
            // await this.loadPipelineCategoryList()
            // for(let i=0;i<this.filterList.length;i++) {
            //     this.filterList[i].data = await this.loadPipelineList(this.filterList[i].pipelineCategoryId)
            // }
            // if(this.filterList.length>0) {
            //     this.filterList.forEach(f=>{
                    this.getDynamicLine(item)
            //     })
            // }
        },
        // 动态创建线流
        getDynamicLine(item) {
            let coordinates = []
            item.data&&item.data.forEach(v=>{
                let arr = []
                let coordinatesCopy = []
                if(v.coordinates&&v.coordinates.length>0) {
                    v.coordinates.forEach(p=>{
                        coordinatesCopy.push({
                            lng: p.longitude,
                            lat: p.latitude
                        })
                    })
                    if(this.$CURRENT_MAP=='GD') {
                        for (const item of coordinatesCopy) {
                            item.lng = parseFloat(item.lng)
                            item.lat = parseFloat(item.lat)
                            arr.push(new AMap.LngLat(item.lng,item.lat))
                        }
                    }
                    if(this.$CURRENT_MAP=='TDT') {
                        for (const item of coordinatesCopy) {
                            item.lng = parseFloat(item.lng)
                            item.lat = parseFloat(item.lat)
                            let result = gcoord.transform([item.lng,item.lat],gcoord.GCJ02,gcoord.EPSG4326)
                            arr.push(new T.LngLat(result[0], result[1]))
                        }
                    }
                    coordinates.push(arr)
                }
            })
            let polyline
            if(this.$CURRENT_MAP=='GD') { 
                polyline = gaode.GDPolyline(coordinates,item)
                this.map.add(polyline)
                this.map.setFitView([ polyline ])
            }
            if(this.$CURRENT_MAP=='TDT') {
                polyline = tianditu.TDTPolyline(coordinates,item)
                this.map.addOverLay(polyline)
                let arr = []
                polyline.ht.forEach(v=>{
                    v.forEach((d,i)=>{
                        arr.push(d)
                    })
                })
                this.map.setViewport(arr)
            }
        },
        // 新增设备
        addDevice() {
            this.equipments.push({
                drawer: false,
                configRadio: '',
                tagDrawer: false,
                tagRadio: '',
                gatewayEid:null,
                eid:null,
                equipmentBrandCode: null,
                equipmentName: null,
                dialogTypeVisible: false,
                tagCustom: [{
                    tagDrawer: false,
                    tagRadio: '',
                    name: '',
                    describe: null,
                }],
                longitude: '',
                latitude: '',
                address: ''
            })
            this.$forceUpdate()
        },
        // 删除设备
        delDevice(index) {
            this.equipments.splice(index,1)
        },
        // 选择设备项目
        selectDevice(dItem) {
            dItem.drawer = true
            if(dItem.equipmentBrandCode) {
                this.current = dItem.equipmentBrandCode
                this.loadListData()
            }
            this.selectCache.gatewayEid = dItem.gatewayEid
            this.selectCache.eid = dItem.eid
            this.selectCache.equipmentName = dItem.equipmentName
            this.selectCache.equipmentBrandCode = dItem.equipmentBrandCode
            this.selectCache.abilityOrPointTable = dItem.abilityOrPointTable
        },
        // 勾选设备项目
        checkConfig(row,dItem) {
            this.selectCache = {}
            dItem.configRadio = row.eid
            this.selectCache = row
        },
        // 确认选择设备项目
        drawerOK(dItem) {
            if(dItem.equipmentBrandCode!=this.selectCache.equipmentBrandCode) {
                dItem.gatewayEid = null
                dItem.eid = null
                dItem.equipmentName = null
                dItem.equipmentBrandCode = null
                dItem.abilityOrPointTable = null
            }
            dItem.gatewayEid = this.selectCache.gatewayEid
            dItem.eid = this.selectCache.eid
            dItem.equipmentName = this.selectCache.equipmentName
            dItem.equipmentBrandCode = this.selectCache.equipmentBrandCode
            this.selectConfigObj = this.selectCache
            dItem.abilityOrPointTable = this.selectConfigObj.abilityOrPointTable
            dItem.drawer = false
        },
        // 选择tag赋值点表数据
        checkTagChange(row,dItem) {
            this.tagSelectObj = row
        },
        // 新增Tag
        addTag(arr) {
            arr.push({
                tagDrawer: false,
                tagRadio: '',
                name: '',
                describe: null,
            })
        },
        // 删除Tag
        delTag(arr) {
            arr.pop()
        },
        // 选择Tag
        selectTag(dItem) {
            dItem.tagDrawer = true
            this.loadTagsList(dItem.eid)
            this.$forceUpdate()
        },
        // 清除Tag
        clearTag(dItem) {
            this.$forceUpdate()
        },
        // 运算符循环Tag选择
        selectTagItem(tagItem,dItem) {
            tagItem.tagDrawer = true
            this.loadTagsList(dItem.eid)
            this.$forceUpdate()
        },
        // 运算符循环Tag清除
        clearTagItem(tagItem) {
            tagItem.name = ''
            tagItem.describe = ''
            this.$forceUpdate()
        },
        // 运算符循环Tag确认
        tagItemDrawerOK(tagItem, dItem) {
            tagItem.name = this.tagSelectObj.pointName
            tagItem.describe = this.tagSelectObj.describe
            tagItem.tagDrawer = false
        },
        // 获取设备
        loadListData() {
            this.tableLoading = true
            this.$ajax.post('equipmentByEcode',{
                pageNum: 0,
                pageSize: 0,
                equipmentBrandCode: this.current,
            }).then(res => {
                res.data.forEach(v=>{
                    v.check = false
                })
                this.tableData = res.data
                this.tableLoading = false
            }).catch(err=>{
                this.tableLoading = false
            })
        },
        // 切换设备项目
        brandToggle(equipmentBrandCode) {
            this.current = equipmentBrandCode
            this.equipmentBrandCode = equipmentBrandCode
            sessionStorage.setItem('equipmentBrandCode',equipmentBrandCode)
            this.loadListData()
        },
        // 获取品牌
        loadBrand() {
            this.treeLoading = true
            this.$ajax.post('equipmentGroupQuery',{
                deviceTypeCodes: ['GatewaySub']
            }).then(res => {
                if(res.data.length==0) {
                    this.treeLoading = false
                }
                this.supplierData = res.data
                if(sessionStorage.getItem('equipmentBrandCode')){
                    this.current = sessionStorage.getItem('equipmentBrandCode')
                    this.equipmentBrandCode = sessionStorage.getItem('equipmentBrandCode')
                    this.loadListData()
                }else{
                    this.current = this.supplierData[0].equipmentBrandCode
                    this.equipmentBrandCode = this.supplierData[0].equipmentBrandCode
                    this.loadListData()
                }
                this.treeLoading = false
            }).catch(err=>{
                this.treeLoading = false
            })
        },
        // 获取Tag列表
        loadTagsList(eid) {
            this.abilityLoading = true
            this.$ajax.post("iotTagsQuery", {}, { 
                eid
            }).then(res=>{
                this.tagTableData = res.data
                this.abilityLoading = false
            }).catch(err=>{
                this.abilityLoading = false
            })
        }
    },
    mounted() {
        if(this.resource=='add') {
            sessionStorage.removeItem('equipmentBrandCode')
            this.loadBrand()
        }
        if(this.resource=='edit') {
            sessionStorage.setItem('equipmentBrandCode',this.current) 
            this.loadBrand()
        }
        this.$forceUpdate()
    },
    destroyed() {
        // 销毁map实例
        if(this.$CURRENT_MAP=='GD') {
            this.map.destroy()
        }
    }
}
</script>
<style lang="less" scoped>
#container-pipeline-map {
    width: 100%;
    height: 525px;
    cursor: pointer;
    position: relative;
    margin-bottom: 20px;
}
.table-tree {
  display: flex;
  height: ~'calc(100% - 56px)';
  .tree {
    min-width: 300px;
    border-right: 4px solid #f6f7fb;
    margin-right: 20px;
  }
  .table {
    flex: 1;
  }
  .operate {
    &:hover {
      color: #409EFF;
    }
  }
  .custom-tree-node {
    font-size: 14px;
  }
}
/deep/.el-drawer__body {
    height: 100%;
    margin-bottom: 50px;
}
.drawer-content {
    margin: 10px 0;
    height: 86%;
    overflow-y: auto;
}
.tree-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tree-park {
  font-size: 14px;
  cursor: pointer;
}
.park-title {
  line-height: 35px;
  padding-left: 20px;
}
.class-icon {
  display: inline-block;
  margin-right: 8px;
}
.bright {
  background: #409EFF;
  color: #FFF;
  border-radius: 5px;
}
.custom-attr {
  border: 1px solid #ebeef5;
//   box-shadow: 0px 0px 10px rgba(211, 211, 211, 0.5);
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 20px;
  .close-icon {
    width: 25px;
    background-color: #ff7979;
    color: #FFF;
    text-align: center;
    line-height: 25px;
    border-radius: 5px;
    cursor: pointer;
    transition: all .3s ease;
    margin-top: 8px;
    &:hover {
        background-color: rgba(255, 121, 121, .7);
    }
  }
}
.el-select {
    width: 100%;
}
.formula-type {
    display: flex;
    align-items: center;
}
.formula-left {
    background-color: #ecf5ff;
    border: 1px solid #d9ecff;
    border-radius: 4px;
    color: #409eff;
    height: 30px;
    padding: 0 10px;
    line-height: 30px;
    font-size: 14px;
}
.f-symbol {
    font-size: 14px;
    text-align: center;
    background: #f2f2f2;
    border-radius: 5px;
    width: 30px;
    line-height: 30px;
    margin: 0 20px;
    color: #333;
}
.tag-explain {
  display:flex;
  justify-content:space-between;
  align-items:center;
  margin:20px 0px;
}
.tag-explain-title {
  font-size:14px;
  margin-left:40px;
  font-weight:bold;
}
.lube-btn {
    width: 100%;
}
.close-lube-btn {
  display: inline-block;
  text-align: center;
  width: 100%;
  color: #409EFF;
  line-height: 32px;
  border-radius: 4px;
  background: #ecf5ff;
  border-color: #409EFF;
  .close-lube {
    cursor: pointer;
  }
}
.pipeline-dialog {
    /deep/.el-dialog__title {
        font-size: 17px;
        font-weight: bold;
    }
    /deep/.el-dialog__body {
        padding: 0px;
    }
}
</style>